import React, { Fragment, useState } from 'react'

import { Text, View, StyleSheet, Image } from '@react-pdf/renderer'
import seal from './assets/images/Seal_dark.png'
import careLabSeal from './assets/images/CareLabSeal.png'
import { isEmpty } from 'lodash'

const styles = StyleSheet.create({
    container: {
        marginTop: '10',
    },
    titleContainer: {
        flexDirection: 'column',
        display: 'flex',

        alignItems: 'center',
    },
    reportTitle: {
        fontSize: 12,
        display: 'flex',

        textTransform: 'uppercase',
    },
    signature: {
        maxWidth: '60%',
        maxHeight: 'auto',
        display: 'flex',

        marginBottom: 4,
    },
    sign: {
        flexDirection: 'row',
        display: 'flex',

        justifyContent: 'space-between',
    },
    seal: {
        maxWidth: '40%',
        display: 'flex',
        maxHeight: 'auto',
        transform: 'rotate(-30)',
    },
    mainHead: {
        display: 'flex',
        alignItems: 'flex-end',
        right: '15%',
        top: '-30%',
    },
    assignedToContainer: {
        height: 50, // Adjust the height as needed
        justifyContent: 'center',
    },

    assignedTo: {
        fontSize: 12,
        marginLeft: 400, // Add left margin as needed
        top: '-20%',
    },
    sign1: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '-10px',
        height: 'auto',
        justifyContent: 'space-between',
    },
    titleContainer1: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '10px 0',
        marginTop: '-5px',
    },
    titleContainer2: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '10px 0',
    },

    signature1: {
        maxWidth: 80,
        height: 80,
        margin: '10px 0',
        marginTop: '-1px',
        padding: 5,
    },
    reportTitle1: {
        fontSize: 10,
        margin: '10px 0',
        textTransform: 'uppercase',
        padding: 40,
        marginTop: -60,
    },
})

const Signature = ({
    testedBy,
    verifiedBy,
    authorizedBy,
    testedByName,
    verifiedByName,
    authorizedByName,
    organizationName,
    assigness,
    type,
    group_type,
}) => {
    return group_type !== 'DYNAMIC_JOBCARD' ? (
        <View style={styles.container} wrap={false}>
            <View style={styles.sign}>
                {(type === 'report' || type === 'testReport') && (
                    <>
                        <View style={styles.titleContainer}>
                            {isEmpty(testedBy) ? (
                                <Text style={styles.reportTitle}>
                                    {testedByName ?? '-'}
                                </Text>
                            ) : (
                                <Image
                                    style={styles.signature}
                                    src={testedBy}
                                />
                            )}
                            <Text style={styles.reportTitle}>Tested By</Text>
                        </View>
                        {/* {organizationName !== 'CareLab' ? ( */}
                        <View style={styles.titleContainer}>
                            <Image style={styles.seal} src={seal} />
                        </View>
                        {/* ) : null} */}
                        <View style={styles.titleContainer}>
                            {isEmpty(verifiedBy) ? (
                                <Text style={styles.reportTitle}>
                                    {verifiedByName ?? '-'}
                                </Text>
                            ) : (
                                <Image
                                    style={styles.signature}
                                    src={verifiedBy}
                                />
                            )}
                            <Text style={styles.reportTitle}>Authorized By</Text>
                        </View>
                    </>
                )}
                {type === 'observationNull' && assigness && (
                    <View style={styles.assignedToContainer}>
                        <Text style={{ ...styles.assignedTo }}>
                            Assigned To: {assigness}
                        </Text>
                    </View>
                )}
            </View>
        </View>
    ) : (
        <View style={styles.container} wrap={false}>
            <View style={styles.sign1}>
                {(type === 'report' || type === 'testReport') && (
                    <>
                        {/* <View style={styles.titleContainer}>
                            {isEmpty(testedBy) ? (
                                <Text style={styles.reportTitle}>
                                    {testedByName ?? '-'}
                                </Text>
                            ) : (
                                <Image style={styles.signature} source={{ uri: testedBy }} />
                            )}
                            <Text style={styles.reportTitle}>Tested By</Text>
                        </View>

                        <View style={styles.titleContainer}>
                            <Image style={styles.seal} source={seal} />
                        </View>

                        <View style={styles.titleContainer}>
                            {isEmpty(verifiedBy) ? (
                                <Text style={styles.reportTitle}>
                                    {verifiedByName ?? '-'}
                                </Text>
                            ) : (
                                <Image style={styles.signature} source={{ uri: verifiedBy }} />
                            )}
                            <Text style={styles.reportTitle}>Verified By</Text>
                        </View> */}

                        <View style={styles.titleContainer1}>
                            {isEmpty(testedBy) ? (
                                <Text style={styles.reportTitle1}>
                                    {testedByName ?? '-'}
                                </Text>
                            ) : (
                                <Image
                                    style={styles.signature1}
                                    src={testedBy}
                                />
                            )}
                            <Text style={styles.reportTitle1}>Tested By</Text>
                        </View>
                        {/* {organizationName !== 'CareLab' ? (
                    <View style={styles.titleContainer}>
                        <Image style={styles.seal} src={seal} />
                    </View>
                ) : null} */}
                        <View style={styles.titleContainer1}>
                            {isEmpty(verifiedBy) ? (
                                <Text style={styles.reportTitle1}>
                                    {verifiedByName ?? '-'}
                                </Text>
                            ) : (
                                <Image
                                    style={styles.signature1}
                                    src={verifiedBy}
                                />
                            )}
                            <Text style={styles.reportTitle1}>Verified By</Text>
                        </View>
                        <View style={styles.titleContainer1}>
                            {/* {isEmpty(authorizedBy) ? (
                        <Text style={styles.reportTitle}>
                            {authorizedByName ?? '-'}
                        </Text>
                    ) : (
                        <Image style={styles.signature} src={authorizedBy} />
                    )}
                    <Text style={styles.reportTitle}>Authorized By</Text> */}
                            {/* {isEmpty(verifiedBy) ? null : (
                        <View style={styles.titleContainer}>
                            <Image
                                style={styles.signature}
                                source={{ uri: verifiedBy }}
                            />
                            <Text style={styles.reportTitle}>Verified By</Text>
                        </View>
                    )} */}
                            {/* {isEmpty(authorizedBy) ? null : (
                                <View style={styles.titleContainer2}>
                                    <Image
                                        style={styles.signature1}
                                        //source={{ uri: authorizedBy }}
                                        src={authorizedBy}
                                    />
                                    <Text style={styles.reportTitle1}>
                                        Authorized By
                                    </Text>
                                </View>
                            )} */}

                            {isEmpty(authorizedBy) ? (
                                <Text style={styles.reportTitle1}>
                                    {authorizedByName ?? '-'}
                                </Text>
                            ) : (
                                <Image
                                    style={styles.signature1}
                                    src={authorizedBy}
                                />
                            )}
                            <Text style={styles.reportTitle1}>
                                Authorized By
                            </Text>
                        </View>
                    </>
                )}
                {type === 'observationNull' && assigness && (
                    <View style={styles.assignedToContainer}>
                        <Text style={{ ...styles.assignedTo }}>
                            Assigned To: {assigness}
                        </Text>
                    </View>
                )}
            </View>
        </View>
    )
}

export default Signature
