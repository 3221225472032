import { StyleSheet, Text, View, Image } from "@react-pdf/renderer";
import QRCodeCanvas from "qrcode";

const fontSize = 10;
const labelfontfamily = "Times-Bold";
const styles = StyleSheet.create({
    dataContainer: {
        display: "flex",
        flexDirection: "row",
        marginTop: 1,
        marginBottom: 1,
        maxWidth: "100%",
    },
    dataContainer1: {
        display: "flex",
        flexDirection: "row",
        marginTop: 2,
        maxwidth: "100%",

        justifyContent: "space-between",
    },
    value: {
        display: "flex",
        fontSize: fontSize,
        flex: 0.8,
        marginTop: 0.5,
        marginRight: 50,
        textTransform: "capitalize",
        //  width: '45%',
    },
    label: {
        display: "flex",
        width: "55%",
        marginTop: 0.5,

        fontSize: fontSize,
        flex: 0.8,

        fontFamily: labelfontfamily,
    },
    value1: {
        display: "flex",
        fontSize: fontSize,
        marginTop: 1,
        marginRight: 10,
        flexWrap: "wrap",
        flex: 2.9,
        //width: '70%',
    },
    label1: {
        display: "flex",
        width: "55%",
        marginTop: 1,

        fontSize: fontSize,
        flex: 0.8,
        fontFamily: labelfontfamily,
    },
    dataContainer1: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "flex-start",
        marginVertical: 5,
        maxWidth: "100%",
    },
    contentWrapper: {
        flex: 8,
        textAlign: "left",
    },
    imageWrapper: {
        flex: 1,
        alignItems: "center",
        justifyContent: "flex-start",
        paddingLeft: 10,
    },
    row: {
        flexDirection: "row",
        alignItems: "flex-start",
        marginBottom: 5,
    },
    labelCare: {
        fontSize: fontSize,
        fontFamily: labelfontfamily,
        width: 117,
        textAlign: "left",
    },
    valueCare: {
        fontSize: fontSize,
        flex: 1,
        textAlign: "left",
        flexWrap: "wrap",
    },
    bar: {
        width: 50,
        height: 50,
    },
});

const CustomerDetails = ({
    data,
    details,
    jobNo,
    customerRef,
    projectDetails,
    organizationName,
    groupType,
    type,
    titleName,
    id,
}) => {
    let urlTitle = String(titleName).replace(/ /g, "%20");

    let baseURL = window.location.origin;

    let url = `${baseURL}/pdf/download/${id}/${urlTitle}?type=${type}&category=download`;

    let canvas;

    // For QR Code
    canvas = document.createElement("canvas");
    QRCodeCanvas.toCanvas(canvas, url);
    const qr = canvas.toDataURL();
    return (
        <View>
            {organizationName === "CareLab" ||
                (groupType === "DYNAMIC_JOBCARD" && organizationName !== "CareLab") ? (
                <>
                    <View style={styles.dataContainer1}>
                        <View style={styles.contentWrapper}>
                            <View style={styles.row}>
                                <Text style={styles.labelCare}>{`\u2022 Customer Name: `}</Text>
                                <Text style={styles.valueCare}>{data?.name}</Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={styles.labelCare}>{`\u2022 Address : `}</Text>
                                <Text style={styles.valueCare}>{data?.address}</Text>
                            </View>
                            {customerRef ? (
                                <View style={styles.row}>
                                    <Text
                                        style={styles.labelCare}
                                    >{`\u2022 Customer Reference : `}</Text>
                                    <Text style={styles.valueCare}>{customerRef}</Text>
                                </View>
                            ) : null}
                        </View>
                        <View style={styles.imageWrapper}>
                            <Image src={qr} style={styles.bar} />
                        </View>
                    </View>
                </>
            ) : (
                <>
                    <View style={styles.dataContainer}>
                        <Text style={styles.label}>{`\u2022 Customer Name`}</Text>
                        <Text style={styles.value}>{data?.name}</Text>
                        <Text style={styles.label}>{`\u2022 Address`}</Text>
                        <Text style={styles.value}>{data?.address}</Text>
                    </View>

                    {projectDetails ? (
                        <View style={styles.dataContainer}>
                            <Text style={styles.label1}>{`\u2022 Project Details`}</Text>
                            {Array.isArray(projectDetails) ? (
                                <Text style={styles.value1}>{projectDetails.join(", ")}</Text>
                            ) : (
                                <Text style={styles.value1}>{projectDetails}</Text>
                            )}
                        </View>
                    ) : null}
                    {customerRef ? (
                        <View style={styles.dataContainer}>
                            <Text style={styles.label1}>{`\u2022 Customer Reference`}</Text>
                            <Text style={styles.value1}>{customerRef}</Text>
                        </View>
                    ) : null}
                </>
            )}
        </View>
    );
};

export default CustomerDetails;
